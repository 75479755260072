import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/fr/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/fr/lexique/">Lexique</a>
                    </li>
                    <li className="last">
                      <a href="/fr/faq-questions-diarrhee/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li id="uilist-hor nav-top nav-lang_nl-BE">
                      <a href="/diarree-op-reis/behandeling-van-reizigersdiarree/">
                        NL
                      </a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr" className="active">
                      <a href="/fr/diarrhee-en-voyage/traitement-de-la-diarrhee-du-voyageur/">
                        FR
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="434b1f7622827b2bf5ba04ec453d0b7ede4e82d8"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/fr/chercher"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <span className="logo">
                  <a href="/fr/">Imodium®</a>
                </span>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/fr/comment-agit-imodium/">
                          Comment agit
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/fr/medicaments-imodium/">
                          Médicaments <br />
                          IMODIUM®
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/fr/la-diarrhee/">
                          Diarrhée – causes
                          <br />
                          et traitement
                        </a>
                      </li>
                      <li className="parent-active" id="nav-sub-8">
                        <a href="/fr/diarrhee-en-voyage/">
                          Diarrhée
                          <br />
                          en voyage
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/fr/diarrhee-chez-les-enfants/">
                          Diarrhée chez
                          <br />
                          les enfants
                        </a>
                      </li>
                      <li className="last" id="nav-sub-10">
                        <a href="/fr/materiel-dinformation-diarrhee/">
                          Matériel
                          <br />
                          d'information
                        </a>
                      </li>
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/fr/diarrhee-en-voyage/"> Diarrhée en voyage </a>
              </li>
              <li>
                <a href="/fr/diarrhee-en-voyage/eviter-la-diarrhee-du-voyageur/">
                  Eviter la diarrhée du voyageur
                </a>
              </li>
              <li className="active">
                <a href="/fr/diarrhee-en-voyage/traitement-de-la-diarrhee-du-voyageur/">
                  Traitement de la diarrhée du voyageur
                </a>
              </li>
              <li className="last">
                <a href="/fr/diarrhee-en-voyage/pharmacie-de-voyage/">
                  Pharmacie de voyage
                </a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>IMODIUM® Instant comprimés</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/Imodium_Instant_2mg_20_tabs_3D_Frontal_NL.jpg"
                    width="170"
                    height="102"
                    alt="IMODIUM® Instant comprimés"
                  />
                </div>
                <p>
                  Aide pratique en cas de diarrhée. Prise sans eau et sans
                  avaler. Fond en quelques&nbsp;secondes sur la langue.
                </p>
                <a href="/fr/medicaments-imodium/imodium-instant/">
                  Plus d'info
                </a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-35">
            <h1>Traitement de la diarrhée du voyageur</h1>
            <div className="img">
              <img
                src="/assets/files/pages/reisen.jpeg"
                width="701"
                height="289"
                alt="Traitement de la diarrhée du voyageur"
              />
            </div>
            <div className="intro row">
              <div className="three columns">
                <h2>Comment traiter la diarrhée du voyageur?</h2>
              </div>
              <div className="nine columns">
                <p>
                  Si par malchance, vous souffrez de diarrhée du voyageur,
                  suivez les recommandations suivantes pour la traiter:
                </p>
              </div>
            </div>
            <ul>
              <li>
                Compensez les pertes de liquide et de sels minéraux en buvant
                beaucoup d’eau en bouteille ou bouillie. Pour les bébés et les
                jeunes enfants, il est recommandé d'utiliser des solutions de
                réhydratation. Les sachets de réhydratation par voie orale sont
                aussi recommandés aux sujets fragiles, aux personnes âgées ou
                aux enfants, qui courent de plus grands risques de
                déshydratation, afin de fournir de l’eau et des électrolytes au
                corps.
              </li>
              <li>
                Prenez un médicament antidiarrhéique disponible en vente libre
                comme{" "}
                <a href="/fr/medicaments-imodium/imodium-instant/">
                  IMODIUM® Instant
                </a>
                , qui fond sur la langue sans eau supplémentaire,&nbsp;afin de
                soulager rapidement les symptômes de la&nbsp;
                <strong>diarrhée</strong>.&nbsp;Lisez attentivement la notice
                avant utilisation. Vous y trouverez la posologie recommandée
                pour les adultes et les enfants à partir de 6 ans.
              </li>
              <li>
                Contactez un médecin si les symptômes persistent plus de 48
                heures, si vous avez de la fièvre (plus de 38&nbsp;°C), ou en
                cas de présence de sang ou de glaires dans vos selles.
              </li>
            </ul>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-fr.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="fr" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
